<template>
    <div class="main">
        <div class="device-notification">
            <a class="device-notification--logo" href="#0">
                <img src="../assets/img/logo.png" alt="Global">
                <p>小卜的秘密基地</p>
            </a>
            <p class="device-notification--message">请使用更大屏幕的设备浏览本网页<br>You won't be disappointed.</p>
        </div>

        <div class="perspective effect-rotate-left">
            <div class="container">
                <div class="outer-nav--return"></div>
                <div id="viewport" class="l-viewport">
                    <div class="l-wrapper">
                        <header class="header">
                            <a class="header--logo" href="#0">
                                <img src="../assets/img/logo.png" alt="Global">
                                <p>小卜的秘密基地</p>
                            </a>
                            <button class="header--cta cta1" @click="toAlist">访问Alist</button>
                            <div class="header--nav-toggle">
                                <span></span>
                            </div>
                        </header>
                        <nav class="l-side-nav">
                            <ul class="side-nav">
                                <li class="is-active"><span>Home</span></li>
                                <li><span>Works</span></li>
                                <li><span>About</span></li>
                                <li><span>Contact</span></li>
                                <li><span>Hire Me</span></li>
                            </ul>
                        </nav>
                        <ul class="l-main-content main-content">
                            <li class="l-section section section--is-active">
                                <div class="intro">
                                    <div class="intro--banner">
                                        <h1 style=" margin-bottom: 0">Welcome to</h1>
                                        <h1 style="font-size: 50px; margin-top: 0;">小卜的秘密基地</h1>
                                        <button class="cta1" @click="toPortfolio">作品集
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 150 118" style="enable-background:new 0 0 150 118;"
                                                xml:space="preserve">
                                                <g transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)">
                                                    <path
                                                        d="M870,1167c-34-17-55-57-46-90c3-15,81-100,194-211l187-185l-565-1c-431,0-571-3-590-13c-55-28-64-94-18-137c21-20,33-20,597-20h575l-192-193C800,103,794,94,849,39c20-20,39-29,61-29c28,0,63,30,298,262c147,144,272,271,279,282c30,51,23,60-219,304C947,1180,926,1196,870,1167z" />
                                                </g>
                                            </svg>
                                            <span class="btn-background"></span>
                                        </button>
                                        <img src="../assets/img/introduction-visual.png" alt="Welcome">
                                    </div>
                                    <div class="intro--options">
                                        <a href="http://pan.wibi8bo.top">
                                            <h3>我的文件仓库</h3>
                                            <p>http://pan.wibi8bo.top</p>
                                        </a>
                                        <a href="http://live.wibi8bo.top">
                                            <h3>我的私影院</h3>
                                            <p>http://live.wibi8bo.top</p>
                                        </a>
                                        <a href="http://cloud.wibi8bo.top">
                                            <h3>我的网盘（供所有人注册使用）</h3>
                                            <p>http://cloud.wibi8bo.top</p>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li class="l-section section">
                                <div class="work">
                                    <h2>I'm Good at</h2>
                                    <div class="work--lockup">
                                        <ul class="slider">
                                            <li class="slider--item slider--item-left">
                                                <a href="#0">
                                                    <div class="slider--item-image">
                                                        <img src="../assets/img/appico/DaVinci.png"
                                                            alt="DaVinci Resolve">
                                                    </div>
                                                    <p class="slider--item-title">DaVinci Resolve</p>
                                                    <p class="slider--item-description">Blackmagic Design's all in one
                                                        solution for Editing, Color,
                                                        VFX, Motion Graphics and Audio Software</p>
                                                </a>
                                            </li>
                                            <li class="slider--item slider--item-center">
                                                <a href="#0">
                                                    <div class="slider--item-image">
                                                        <img src="../assets/img/appico/pr.png" alt="Adobe Premiere Pro">
                                                    </div>
                                                    <p class="slider--item-title">Adobe Premiere Pro</p>
                                                    <p class="slider--item-description">Adobe's Professional Video
                                                        Editing Software</p>
                                                </a>
                                            </li>
                                            <li class="slider--item slider--item-right">
                                                <a href="#0">
                                                    <div class="slider--item-image">
                                                        <img src="../assets/img/appico/ae.png"
                                                            alt="Adobe After Effects">
                                                    </div>
                                                    <p class="slider--item-title">Adobe After Effects</p>
                                                    <p class="slider--item-description">Adobe's Motion Graphics Software
                                                    </p>
                                                </a>
                                            </li>
                                            <li class="slider--item">
                                                <a href="#0">
                                                    <div class="slider--item-image">
                                                        <img src="../assets/img/appico/ps.png" alt="Adobe Photoshop">
                                                    </div>
                                                    <p class="slider--item-title">Adobe Photoshop</p>
                                                    <p class="slider--item-description">Adobe's Professional Image
                                                        Editing and Design Software</p>
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="slider--prev">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 150 118" style="enable-background:new 0 0 150 118;"
                                                xml:space="preserve">
                                                <g transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)">
                                                    <path d="M561,1169C525,1155,10,640,3,612c-3-13,1-36,8-52c8-15,134-145,281-289C527,41,562,10,590,10c22,0,41,9,61,29
                    c55,55,49,64-163,278L296,510h575c564,0,576,0,597,20c46,43,37,109-18,137c-19,10-159,13-590,13l-565,1l182,180
                    c101,99,187,188,193,199c16,30,12,57-12,84C631,1174,595,1183,561,1169z" />
                                                </g>
                                            </svg>
                                        </div>
                                        <div class="slider--next">
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 150 118" style="enable-background:new 0 0 150 118;"
                                                xml:space="preserve">
                                                <g transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)">
                                                    <path
                                                        d="M870,1167c-34-17-55-57-46-90c3-15,81-100,194-211l187-185l-565-1c-431,0-571-3-590-13c-55-28-64-94-18-137c21-20,33-20,597-20h575l-192-193C800,103,794,94,849,39c20-20,39-29,61-29c28,0,63,30,298,262c147,144,272,271,279,282c30,51,23,60-219,304C947,1180,926,1196,870,1167z" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="l-section section">
                                <div class="about">
                                    <div class="about--banner">
                                        <h2>We<br>believe in<br>Unlimited<br>Progress</h2>
                                        <a href="https://url.v.tencent.com/jzw1BxM6" target="_blank">作品集
                                            <span>
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 150 118" style="enable-background:new 0 0 150 118;"
                                                    xml:space="preserve">
                                                    <g
                                                        transform="translate(0.000000,118.000000) scale(0.100000,-0.100000)">
                                                        <path
                                                            d="M870,1167c-34-17-55-57-46-90c3-15,81-100,194-211l187-185l-565-1c-431,0-571-3-590-13c-55-28-64-94-18-137c21-20,33-20,597-20h575l-192-193C800,103,794,94,849,39c20-20,39-29,61-29c28,0,63,30,298,262c147,144,272,271,279,282c30,51,23,60-219,304C947,1180,926,1196,870,1167z" />
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>
                                        <img src="../assets/img/about-visual.png" alt="About">
                                    </div>
                                    <div class="about--options">
                                        <a href="https://mp.weixin.qq.com/s/U7Hm_yA66MPfyGHfwsOyCw" target="_blank">
                                            <h3>《起点》</h3>
                                        </a>
                                        <a href="https://mp.weixin.qq.com/s/1gpGUf2fzhHq4GxRThEcDg" target="_blank">
                                            <h3>我不是湖大中年</h3>
                                        </a>
                                        <a href="https://www.xinpianchang.com/a11669009" target="_blank">
                                            <h3>倾尽一生报党恩</h3>
                                        </a>
                                    </div>
                                </div>
                            </li>
                            <li class="l-section section">
                                <div class="contact">
                                    <div class="contact--lockup">
                                        <div class="modal">
                                            <div class="modal--information">
                                                <p>湖南省长沙市岳麓区绿地中央广场</p>
                                                <a href="mailto:1852236970@qq.com">1852236970@qq.com</a>
                                                <a href="tel:+8615568116030">+86 155 6811 6030</a>
                                            </div>
                                            <ul class="modal--options">
                                                <li><a href="https://v.douyin.com/iNAefJGH/"
                                                        style="font-family: DouyinSans;font-size: 16px;">抖音</a></li>
                                                <li><a href="https://space.bilibili.com/192011831"
                                                        style="padding: 4px 0px"><img id="bilibili"
                                                            src="../assets/img/bilibili.png" alt="bilibili"
                                                            height="28px" /></a></li>
                                                <li>
                                                    <div ref="myVueInstance">
                                                        <a @click="centerDialogVisible = true"
                                                            style="padding: 0px 0px; height: 41px; background-color: #29AD68"><img
                                                                id="weixin" src="../assets/img/weixin.png" alt="weixin"
                                                                height="28px" style="margin: 7px 0px;" /></a>
                                                        <el-dialog title="" :visible.sync="centerDialogVisible"
                                                            width="300px" top="25vh" center :append-to-body="true"
                                                            v-loading="loading">
                                                            <el-image :src="src" @load="handleImageLoad"></el-image>
                                                        </el-dialog>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="l-section section">
                                <div class="hire">
                                    <h2>You want me to do</h2>
                                    <!-- checkout formspree.io for easy form setup -->
                                    <form class="work-request" action="php/register.php" method="post">
                                        <div class="work-request--options">
                                            <span class="options-a">
                                                <input id="opt-1" type="checkbox" value="Film Editing" name="opt1">
                                                <label for="opt-1">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;"
                                                        xml:space="preserve">
                                                        <g
                                                            transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                                                            <path
                                                                d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z" />
                                                        </g>
                                                    </svg>
                                                    Film Editing
                                                </label>
                                                <input id="opt-2" type="checkbox" value="Motion Graphics" name="opt2">
                                                <label for="opt-2">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;"
                                                        xml:space="preserve">
                                                        <g
                                                            transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                                                            <path
                                                                d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z" />
                                                        </g>
                                                    </svg>
                                                    Motion Graphics
                                                </label>
                                                <input id="opt-3" type="checkbox" value="Photography" name="opt3">
                                                <label for="opt-3">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;"
                                                        xml:space="preserve">
                                                        <g
                                                            transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                                                            <path
                                                                d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z" />
                                                        </g>
                                                    </svg>
                                                    Photography
                                                </label>
                                            </span>
                                            <span class="options-b">
                                                <input id="opt-4" type="checkbox" value="Image Processing" name="opt4">
                                                <label for="opt-4">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;"
                                                        xml:space="preserve">
                                                        <g
                                                            transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                                                            <path
                                                                d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z" />
                                                        </g>
                                                    </svg>
                                                    Image Processing
                                                </label>
                                                <input id="opt-5" type="checkbox" value="Live Streaming" name="opt5">
                                                <label for="opt-5">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;"
                                                        xml:space="preserve">
                                                        <g
                                                            transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                                                            <path
                                                                d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z" />
                                                        </g>
                                                    </svg>
                                                    Live Streaming
                                                </label>
                                                <input id="opt-6" type="checkbox" value="Others" name="opt6">
                                                <label for="opt-6">
                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                        viewBox="0 0 150 111" style="enable-background:new 0 0 150 111;"
                                                        xml:space="preserve">
                                                        <g
                                                            transform="translate(0.000000,111.000000) scale(0.100000,-0.100000)">
                                                            <path
                                                                d="M950,705L555,310L360,505C253,612,160,700,155,700c-6,0-44-34-85-75l-75-75l278-278L550-5l475,475c261,261,475,480,475,485c0,13-132,145-145,145C1349,1100,1167,922,950,705z" />
                                                        </g>
                                                    </svg>
                                                    Others
                                                </label>
                                            </span>
                                        </div>
                                        <div class="work-request--information">
                                            <div class="information-name">
                                                <input id="name" type="text" spellcheck="false" maxlength="5"
                                                    name="name" required="required">
                                                <label for="name">Name</label>
                                            </div>
                                            <div class="information-email">
                                                <input id="email" type="text" spellcheck="false" maxlength="20"
                                                    name="email" required="required">
                                                <label for="email">Email or Wechat</label>
                                            </div>
                                        </div>
                                        <input type="submit" value="Send Request" name="submit" id="submit">
                                    </form>
                                </div>
                                <div class="icp">
                                    <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2024047398号-1</a>
                                    <img src="../assets/img/gongan.png" alt="gongan" height="15px" />
                                    <a href="https://beian.mps.gov.cn/#/query/webSearch?code=43010402001710\"
                                        rel="noreferrer" target="_blank">湘公网安备43010402001710</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ul class="outer-nav">
                <li class="is-active">Home</li>
                <li>Works</li>
                <li>About</li>
                <li>Contact</li>
                <li>Hire Me</li>
            </ul>
        </div>
    </div>
</template>

<script>
import wxqrcode from '../assets/img/wxqrcode.jpg';
export default {
    name: 'indexPage',
    data: function () {
        return {
            centerDialogVisible: false,
            src: wxqrcode,
            loading: true
        }
    },
    mounted() {
        this.checkQueryParams();
    },
    methods: {
        toAlist() {
            window.location.href = "http://pan.wibi8bo.top";
        },
        toPortfolio() {
            window.location.href = "https://url.v.tencent.com/jzw1BxM6";
        },
        handleImageLoad() {
            this.loading = false;
        },
        open() {
            this.$message({
                message: '感谢信任！我将很快与您取得联系～',
                type: 'success'
            });
        },
        checkQueryParams() {
            const urlParams = new URLSearchParams(window.location.search);
            const status = urlParams.get('submit');
            if (status === 'success') {
                this.open();
            }
            if (history.replaceState) {
                const newURL = window.location.href.split('?')[0]; // 移除参数部分
                history.replaceState({}, document.title, newURL);
            } else {
                // 如果浏览器不支持 history.replaceState，则只能保留原始 URL
                console.warn('浏览器不支持 history.replaceState');
            }
        }
    }
}
</script>

<style>
@import '../assets/css/main.css';

@font-face {
    font-family: DouyinSans;
    src: url('../assets/css/fonts/DouyinSansBold.otf')
}

.weixin-dialog {
    width: 20% !important;
}

.icp {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 700px;
    max-width: 75%;
    height: 3%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
}

.icp a {
    text-align: center;
    color: #fff !important;
    font-family: Arial, 微软雅黑;
    text-decoration: none;
    cursor: pointer;
}

.icp>a:nth-child(1) {
    margin: 0 15px;
}

.icp a:hover {
    color: #fff !important;
}

.icp a:visited {
    color: #fff !important;
}

.el-dialog__body {
    min-height: 360px;
}
</style>