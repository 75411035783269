<template>
  <div id="app">
    <indexPage />
  </div>
</template>

<script>
import indexPage from './components/indexPage.vue'
export default {
  name: 'App',
  components: {
    indexPage
  }
}
</script>

<style></style>
